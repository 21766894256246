import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('members', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('members/add', payload)
	console.log(data)

	return data
}

const Update = async (ID, payload) => {
	const { data } = await Axios.post('members/' + ID, payload)
	console.log(data)

	return data
}

const Member = async (ID) => {
	const { data } = await Axios.get('members/' + ID)
	console.log(data)

	return data
}

const Pull = async () => {
	const { data } = await Axios.post('members/pull')

	return data
}

const Status = async (ID, payload) => {
	const { data } = await Axios.post('members/' + ID + '/status', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

export default { Query, Add, Update, Member, Pull, Status }
