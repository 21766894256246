import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		component: () => import('@/views/member/Layout'),
		children: [
			{
				path: '',
				name: 'members-archive',
				component: () => import('@/views/member/Archive')
			},
			{
				path: ':ID',
				name: 'members-member',
				component: () => import('@/views/member/Edit')
			},
			{
				path: ':ID/status',
				name: 'members-status',
				component: () => import('@/views/member/Status')
			}
		]
	},
	{
		path: '/invoices',
		component: () => import('@/views/invoice/Layout'),
		children: [
			{
				path: '',
				name: 'invoices-archive',
				component: () => import('@/views/invoice/Archive')
			},
			{
				path: 'add',
				name: 'invoices-add',
				component: () => import('@/views/invoice/Add')
			},
			{
				path: ':ID',
				name: 'invoices-invoice',
				component: () => import('@/views/invoice/Edit')
			}
		]
	},
	{
		path: '/messages',
		component: () => import('@/views/message/Layout'),
		children: [
			{
				path: '',
				name: 'messages-archive',
				component: () => import('@/views/message/Archive')
			},
			{
				path: 'add',
				name: 'messages-add',
				component: () => import('@/views/message/Add')
			},
			{
				path: ':ID',
				name: 'messages-message',
				component: () => import('@/views/message/Edit')
			}
		]
	},
	{
		path: '/users',
		component: () => import('@/views/user/Layout'),
		children: [
			{
				path: '',
				name: 'users-archive',
				component: () => import('@/views/user/Archive')
			},
			{
				path: 'add',
				name: 'users-add',
				component: () => import('@/views/user/Add')
			}
		]
	},
	{
		path: '/uploads',
		component: () => import('@/views/upload/Layout'),
		children: [
			{
				path: '',
				name: 'uploads-archive',
				component: () => import('@/views/upload/Archive')
			},
			{
				path: 'add',
				name: 'uploads-add',
				component: () => import('@/views/upload/Add')
			}
		]
	},
	{
		path: '/settings',
		component: () => import('@/views/settings/Layout'),
		children: [
			{
				path: 'banks',
				name: 'settings-banks',
				component: () => import('@/views/settings/Banks')
			},
			{
				path: 'invoice-templates',
				name: 'settings-invoice-templates',
				component: () => import('@/views/settings/InvoiceTemplates')
			}
		]
	}
]

export default createRouter({ history: createWebHistory(), routes })
