import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import Axios from 'axios'
import Router from '@/router'
import Store from '@/store'
import Main from '@/Main'
import '@/scss/main.scss'

Store.commit('Backend', process.env.VUE_APP_BACKEND_URL)

Axios.defaults.baseURL = Store.state.backend + '/api/'

if ($cookies.get('token')) {
	Axios.defaults.headers.common['Authorization'] = 'Bearer ' + $cookies.get('token')
}

Store.dispatch('Config')
Store.dispatch('Session')

createApp(Main)
.use(VueCookies, { expires: '7d', path: '/', domain: '', secure: '', sameSite: 'Lax' })
.use(Router)
.use(Store)
.mount('#app')
