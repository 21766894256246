import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('messages', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('messages/add', payload)
	console.log(data)

	return data
}

const Update = async (ID, payload) => {
	const { data } = await Axios.post('messages/' + ID, payload)
	console.log(data)

	return data
}

const Message = async (ID) => {
	const { data } = await Axios.get('messages/' + ID)
	console.log(data)

	return data
}

export default { Query, Add, Update, Message }
