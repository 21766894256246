import Axios from 'axios'
import User from './modules/User'
import Settings from './modules/Settings'
import Member from './modules/Member'
import Invoice from './modules/Invoice'
import Message from './modules/Message'
import Upload from './modules/Upload'

const Config = async () => {
	const { data } = await Axios.get('config')
	console.log(data)

	return data
}

const Session = async () => {
	if (!Axios.defaults.headers.common['Authorization']) {
		return
	}

	const { data } = await Axios.get('session')
	console.log(data)

	return data
}

const Login = async (email, password) => {
	const { data } = await Axios.post('login', { email, password })
	console.log(data)

	if (data.token) {
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
	}

	return data
}

const Logout = async () => {
	await Axios.post('logout')

	delete Axios.defaults.headers.common['Authorization']
}

export default { User, Settings, Member, Invoice, Message, Upload, Config, Session, Login, Logout }
