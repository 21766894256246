import Axios from 'axios'

const Templates = async (payload) => {
	const { data } = await Axios.post('invoices/templates', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

const Query = async (payload) => {
	const { data } = await Axios.post('invoices', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('invoices/add', payload)
	console.log(data)

	return data
}

const Update = async (ID, payload) => {
	const { data } = await Axios.post('invoices/' + ID, payload)
	console.log(data)

	return data
}

const Invoice = async (ID) => {
	const { data } = await Axios.get('invoices/' + ID)
	console.log(data)

	return data
}

const Printable = async (ID) => {
	const { data } = await Axios({
		url: 'invoices/' + ID + '/printable',
		method: 'GET',
		responseType: 'blob'
	})

	return data
}

const Paid = async (ID) => {
	const { data } = await Axios.post('invoices/' + ID + '/paid')
	console.log(data)

	return data
}

export default { Templates, Query, Add, Update, Invoice, Printable, Paid }
