import { createStore } from 'vuex'
import API from '@/backend'

const state = {}
const mutations = {}
const actions = {}
const getters = {}

state.backend = ''
state.config = null
state.session = null

mutations.Backend = (state, backend) => state.backend = backend
mutations.Config = (state, config) => state.config = config
mutations.Session = (state, session) => state.session = session

actions.Config = async ({ commit }) => {
	commit('Config', await API.Config())
}

actions.Session = async ({ commit }) => {
	commit('Session', await API.Session())
}

export default createStore({ state, getters, mutations, actions })
