<script setup>
import { ref } from 'vue'
import Store from '@/store'
import API from '@/backend'
import Button from '@/components/Button'
import TextInput from '@/components/TextInput'
import Message from '@/components/Message'

const $context = ref({})

const Submit = async () => {
	$context.value.config['message'].properties.message = ''

	if (!$context.value.fields['email']) {
		$context.value.config['message'].properties.message = 'Email is required!'
		return
	}

	if (!$context.value.fields['password']) {
		$context.value.config['message'].properties.message = 'Password is required!'
		return
	}

	const { token, Exception } = await API.Login($context.value.fields['email'], $context.value.fields['password'])

	if (token) {
		$cookies.set('token', token)
		Store.dispatch('Session')
	}

	if (Exception) {
		$context.value.config['message'].properties.message = Exception.message
	}
}

$context.value.config = {
	'message': {
		properties: {
			class: 'message-error',
			icon: 'fa-solid fa-triangle-exclamation',
			message: ''
		}
	},
	'button': {
		properties: {
			class: 'default',
			text: 'Login',
			icon: 'fas fa-sign-in'
		}
	}
}

$context.value.fields = {
	'email': '',
	'password': ''
}
</script>

<template>
	<div class="view_login">
		<div>
			<div>
				<TextInput v-model="$context.fields['email']" type="email" placeholder="Email" icon="fas fa-user" class="alternative"/>
			</div>
			<div>
				<TextInput v-model="$context.fields['password']" type="password" placeholder="Password" icon="fas fa-key" class="alternative"/>
			</div>
			<div>
				<Message :properties="$context.config['message'].properties"/>
			</div>
			<div>
				<Button @click="Submit()" :properties="$context.config['button'].properties"/>
			</div>
		</div>
	</div>
</template>
