import Axios from 'axios'

const Query = async (payload) => {
	const { data } = await Axios.post('users', { ...payload, 'page': payload.pagination.page })
	console.log(data)

	return data
}

const Add = async (payload) => {
	const { data } = await Axios.post('users/add', payload)
	console.log(data)

	return data
}

export default { Query, Add }
