import Axios from 'axios'

const Query = async () => {
	const { data } = await Axios.get('settings')
	console.log(data)

	return data
}

const Update = async (payload) => {
	const { data } = await Axios.post('settings', { 'settings': { ...payload }})
	console.log(data)

	return data
}

export default { Query, Update }
